export default {
	addnew: {
        credit: "信譽分",
        messageNotice: "尊敬的客戶您好！ 有您的新資訊請查看。",
        messages: "我的消息",
        authReal: "您已完成初級認證",
		completed: "已完成",
		position: "持倉中",
		all: "全部",
		submit: "提交",
        realFirst: "請先進行身份認證",
        payFirst: "请先设置提款密码",
        password1: "修改登入密碼",
        password2: "修改提款密碼",
        paypassTit1: "請設定您的提款密碼",
        oldPass: "輸入原提款密碼",
        allView: "全部已读",
        confirmOrder: "确认订单",
        name: "名字",
        fee: "手续费",
        orderCycle: "选择时间",
        loan: "贷款",
        loanTit: "助力贷款",
        loanHostory: "贷款记录",
        labelProductTitle: "产品名字",
        immediateRepayment: "立即还款",
        loadAmount: "预期贷款金额",
        RepaymentCycle: "贷款偿还周期",
        day: "天",
        dailyRate: "日利率",
        repaymentMethod: "还款方式",
        lendingCompany: "贷款公司",
        loanTip: "信用贷款（请确保图片清晰可见）",
        loanTip1: "上传房产证书",
        loanTip2: "收入证明（雇佣关系）",
        loanTip3: "银行对账单详细信息",
        loanTip4: "上传身份证正面照片",
        interest: "利益",
        repaid: "已还款",
        unpaid: "未还款",
        tab1: "币币",
        tab2: "合约",
        tab3: "期权",
        available: "可用額度",
        pending: "锁定",
        equivalent: "折合",
        fundTransfer: "資金轉移",
        flashExchange: "闪兑",
        select_breed: "选择币种",
        from: "从",
        to: "至",
        transferQuantity: "转移数量",
        repaymentMethod1:"到期一次还款"
    },
    addNew: {
        place_card_back: "點擊上傳身份證反面照",
        side1: "訂單管理",
        side2: "修改密碼",
        side3: "語言",
        side4: "登出",
        product: "產品",
        direction: "方向",
        cycle: "交易期間",
        long_buy: "做多買入",
        short_sell: "做空賣出",
        open_time: "開倉時間",
        close_time: "平倉時間",
        open_price: "開倉價格",
        close_price: "平倉價格",
        number: "數量",
        profit_loss: "盈虧",
        commission: "手續費",
        account_type: "賬變類型",
        type1: "幣幣帳戶",
        type2: "法幣帳戶",
        type3: "合約帳戶",
        type4: "期權帳戶",
        type5: "理財帳戶",
        message1: "請輸入金額",
        message2: "帳戶餘額不足",
        place_transPsd: "請輸入交易密碼",
		countpath: "倒數計時結束自動跳轉到訂單頁面",
		nowpath: "立即跳轉",
    },
    common: {
        app_name: "Blur",
        confirm: "確認",
        cancel: "取消",
        more: "更多",
        main: "主圖",
        sub: "副圖",
        empty: "暫無數據",
        success: "成功",
        copy_success: "複製成功！",
        copy_error: "複製失敗！",
        passed: "已通過",
        rejectrd: "已拒絕",
        in_review: "稽核中",
        nodata: "暫時沒有內容",
        delete: "删除",
        add: "新增",
        buy: "買",
        sell: "賣",
        max: "最大",
        all: "全部",
        logout: "確定要登出嗎？",
        walletLink: "請在錢包中打開此連接！",
        connectWallet: "連接錢包",
    },
    home: {
        nav1: "分享",
        nav2: "錢包",
        nav3: "快訊",
        nav4: "教程",
        nav5: "客服",
        nav6: "挖礦",
        nav7: "公司",
        quick_p1: "快速充幣",
        quick_p2: "支持TRC20-USDT、ERC20-USDT等",
        sort_tab1: "漲幅榜",
        sort_tab2: "24h成交量",
        sort_tit: "市場",
        sort_price: "最新價格",
        sort_rate: "24h漲跌幅",
        sort_vol: "成交量",

    },
    form: {
        place_username_email: "請輸入用戶名/郵箱",
        place_password: "請輸入密碼",
        place_confirm_password: "請再次輸入密碼",
        place_email: "請輸入郵箱",
        send_email: "發送郵件",
        place_email_captcha: "請輸入郵箱驗證碼",
        place_username: "請輸入用戶名",
        place_yzm: "請輸入驗證碼",
        place_invite: "請輸入邀請人ID",
        place_inner: "請輸入內容",
        place_select: "請選擇",
        label_oldpwd: "原登入密碼",
        place_oldpwd: "請輸入原登入密碼",
        label_newpwd: "新登入密碼",
        place_newpwd: "請輸入新登入密碼",
        label_crmpwd: "確認新登入密碼",
        place_crmpwd: "請再次輸入新登入密碼",
        place_country: "請選擇國家",
        place_name: "請輸入您的姓名",
        place_card_number: "請輸入您的身份證號或者護照號",
        place_card_main: "點擊上傳身份證正面照",
        label_currency: "貨幣",
        place_currency: "請選擇貨幣",
        label_account_number: "帳戶號碼",
        place_account_number: "請輸入帳戶號碼",
        message_buy_amount: "請輸入購買金額",
        place_buy_tip: "至少買入",
    },

    foot: {
        nav1: "主頁",
        nav2: "行情",
        nav3: "交易",
        nav4: "資產",
    },
    account: {
        login_title: "歡迎來到",
        register_title: "歡迎註冊",
        contact: "點擊聯系客服",
        login: "登入",
        no_account: "還沒有帳號？",
        free_register: "免費註冊",
        welcome_register: "歡迎註冊",
    },
    asset: {
        asset: "資產",
        total_asset: "總資產",
        deposit: "充值",
        withdraw: "提現",
        available: "可用",
        freeze: "凍結",
        total: "總計",
        wallet: "錢包",
        select_link: "選擇主鏈",
        deposit_address: "充值地址",
        deposit_tip: "請不要向這個地址充值除USDT之外的資產,任何充入此地址的其他資產將不可找回",
    },
    chart: {
        now: "當前",
        open_price: "開盤價",
        trans_amount: "成交金額",
        volumes: "成交量",
        height: "最高",
        low: "最低",
        buy: "買入",
        sell: "賣出",
        buy_more: "買多",
        sell_short: "賣空",
        buy_long: "做多",
        short_sell: "做空",
        spots: "現貨",
        foreign_exchange: "外匯",
        market: "行情",
        order_tab1: "持倉",
        order_tab2: "掛單",
        order_tab3: "已平倉",
        surplus: "結餘",
        available_balance: "可用餘額",
        net_value: "淨值",
        advance_charge: "預付款",
        unrealized_profits: "未實現盈利",
        open_price2: "開倉價格",
        now_price: "當前價格",
        lever_title: "調整杠杆",
        lever: "杠杆",
        check_price: "限價",
        market_price: "市價",
        number: "數量",
        total_amount: "總金額",
        price: "價格",
        now_entrust: "當前委託",
        history_entrust: "歷史委託",
        holding_positions: "持有倉位",
        warehouse: "逐倉",
        margin: "保證金",
        from: "從",
        to: "至",
        pay: "支付",
        balance: "餘額",
        get: "得到",
        flash_exchange: "閃兌",
        hand_number: "手數",
        registration_price: "掛單價",
        stop_profit: "止盈",
        stop_loss: "止損",
        need_margin: "需付保證金",
        current_price: "現價",
        direction: "方向",
        currency_trade: "貨幣交易",
        delivery_time: "交割時間（左滑收益更高）",
        second: "秒",
        buy_amount: "買入金額",
        fee: "手續費",
        confirm_order: "確認訂單",
        trade_history: "交易歷史",
        turnover_number: "成交數量",
        turnovered: "已成交",
        opened: "待開倉",
        progress: "進行中",
        ended: "已結束",
        revoked: "已撤銷",
        complete: "完成",
        time: "時間",
        operate: "操作",
        closing_position: "平倉",
        buy_price: "買入價",
    },
    market: {
        currency_trade: "貨幣交易",
        spots: "現貨",
        contract: "合約",
        adjustment_accounts: "調賬",
    },
    table: {
        market: "市場",
        rate: "24h漲跌幅",
        open_price: "開盤價",
        last_price: "最新價",
    },
    my: {
        modify_login_pwd: "修改登入密碼",
        company: "公司",
        help_center: "幫助中心",
        history: "貨幣交易歷史",
        buy: "買入",
        sell: "賣出",
        buy_price: "買入價格",
        type: "類型",
        amount: "金額",
        final_price: "最終價格",
        profit_loss: "盈虧",
        state: "狀態",
        loss: "虧損",
        profit: "盈利",
        news: "快訊",
        authentication: "認證",
        service: "客服",
        asset: {
            amount_record: "金額記錄",
            deposit_record: "充值記錄",
            withdraw_record: "提現記錄",
        },
        help: {
            p1: "隨時隨地為您提供服務",
            h1: "常見問題",
        },
        share: {
            share: "分享",
            invite_link: "邀請連結",
            copy_link: "複製連結",
        },
        wallet: {
            title: "錢包地址",
            account_number: "帳戶號碼",
            add_address: "新增地址",

        }
    },
    finance: {
        finance_tit: "鎖倉挖礦",
        finance_label1: "預計今日收益",
        finance_label2: "累計收益",
        finance_label3: "託管的訂單",
        subscribe: "申購",
        day: "天",
        record: "記錄",
        deposit_amount: "充值金額",
        received_amount: "到賬金額",
        exchange_rate: "匯率",
        ad_p1: "挖礦賺不停",
        ad_p2: "鎖倉挖礦是通過將usdt託管給平臺超算力礦機在平臺礦池中進行挖礦收益",
        join: "我要參與",
        ad_p3: "產品亮點",
        ad_p4: "舉個例子",
        ad_tit1: "隨存隨取",
        ad_tit2: "每日下發",
        ad_tit3: "安全保障",
        ad_tit4: "收益不斷",
        ad_tit5: "當天起息",
        ad_des1: "派息週期",
        ad_des2: "活期利息",
        ad_des3: "100%安全",
        ad_des4: "節假日收益",
        ad_des5: "成功存入後",
        ad_sub_tit1: "收益計算",
        ad_sub_tit2: "違約金",
        ad_sub_p1: "會員在平臺鎖倉10000U,選擇了週期為5天,日產出為鎖倉金額的0.3%~0.4%的理財產品,則每天產出如下：",
        ad_sub_p2: "最低：10000U x 0.3%=30 U",
        ad_sub_p3: "最高：10000U x 0.4%=40 U",
        ad_sub_p4: "即5天后可以獲得150U~200U的收益,收益每日下發,下發的收益可隨時存取,鎖倉本金到期後,自動轉入您的資產帳戶。",
        ad_sub_p5: "若您希望轉出未到期的本金,則會產生違約金,違約金=違約結算比例*剩餘天數*鎖倉數量。",
        ad_sub_p6: "舉例：該鎖倉挖礦的違約結算比例為0.5%,剩餘3天到期,鎖倉數量為1000,則違約金=0.5%*3*1000=15U,實際退還本金為1000U-15U=985U",
    },
    city: {
        albania: "阿爾巴尼亞",
        algeria: "阿爾及利亞",
        argentina: "阿根廷",
        armenia: "亞美尼亞",
        australia: "澳大利亞",
        pakistan: "巴基斯坦",
        austria: "奧地利",
        bahrain: "巴林",
        belgium: "比利時",
        bosnia_and_Herzegovina: "波士尼亞和黑塞哥維那",
        brazil: "巴西",
        brunei: "汶萊",
        bulgaria: "保加利亞",
        cambodia: "柬埔寨",
        canada: "加拿大",
        cameroon: "喀麥隆",
        chile: "智利",
        colombia: "哥倫比亞",
        costa_Rica: "哥斯达黎加",
        croatia: "克羅地亞",
        cyprus: "塞浦路斯",
        czech_Republic: "捷克共和國",
        denmark: "丹麥",
        dominican_Republic: "多米尼加共和国",
        egypt: "埃及",
        estonia: "愛沙尼亞",
        ethiopia: "埃塞俄比亚",
        finland: "芬蘭",
        france: "法國",
        georgia: "格魯吉亞",
        germany: "德國",
        ghana: "加納",
        greece: "希臘",
        guyana: "蓋亞那",
        honduras: "洪都拉斯",
        hong_Kong_China: "中國香港",
        hungary: "匈牙利",
        iceland: "冰島",
        ireland: "愛爾蘭",
        italy: "義大利",
        india: "印度",
        indonesia: "印度尼西亞",
        israel: "以色列",
        iran: "伊朗",
        iraq: "伊拉克",
        japan: "日本",
        kazakstan: "哈薩克共和國",
        kenya: "肯尼亚",
        korea: "韓國",
        kuwait: "科威特",
        kyrgyzstan: "吉爾吉斯斯坦",
        laos: "老撾",
        latvia: "拉脫維亞",
        lithuania: "立陶宛",
        luxembourg: "盧森堡",
        macao_China: "中國澳門",
        macedonia: "馬其頓",
        malaysia: "馬來西亞",
        malta: "馬爾他",
        mexico: "墨西哥",
        moldova: "摩爾達瓦",
        monaco: "摩納哥",
        mongolia: "蒙古",
        montenegro: "黑山共和國",
        morocco: "摩洛哥",
        myanmar: "緬甸",
        netherlands: "荷蘭",
        new_Zealand: "新西蘭",
        nepal: "尼泊爾",
        nigeria: "尼日利亞",
        norway: "挪威",
        oman: "阿曼",
        palestine: "巴勒斯坦",
        panama: "巴拿馬",
        paraguay: "巴拉圭",
        peru: "秘魯",
        philippines: "菲律賓",
        poland: "波蘭",
        portugal: "葡萄牙",
        puerto_Rico: "波多黎各",
        qatar: "卡塔爾",
        romania: "羅馬尼亞",
        russia: "俄羅斯",
        republic_of_Trinidad_and_Tobago: "特立尼达和多巴哥共和国",
        rwanda: "卢旺达",
        saudi_Arabia: "沙烏地阿拉伯",
        serbia: "塞爾維亞",
        singapore: "新加坡",
        slovakia: "斯洛伐克",
        slovenia: "斯洛文尼亞",
        south_Africa: "南非",
        spain: "西班牙",
        sri_Lanka: "斯里蘭卡",
        sweden: "瑞典",
        switzerland: "瑞士",
        taiwan_China: "中國臺灣",
        tajikistan: "塔吉克共和國",
        tanzania: "坦尚尼亞",
        thailand: "泰國",
        turkey: "土耳其",
        turkmenistan: "土庫曼共和國",
        ukraine: "烏克蘭",
        united_Arab_Emirates: "阿拉伯聯合大公國",
        united_Kingdom: "英國",
        united_States: "美國",
        uzbekistan: "烏茲別克",
        venezuela: "委內瑞拉",
        vietnam: "越南",
        afghanistan: "阿富汗",
        angola: "安哥拉",
        azerbaijan: "阿塞拜疆",
        bangladesh: "孟加拉",
        belarus: "白俄羅斯",
        belize: "貝裡斯",
        benin: "貝南",
        bhutan: "不丹",
        bolivia: "玻利維亞",
        botswana: "博茨瓦纳",
        british_Virgin_Islands: "英屬維京群島",
        burkina_Faso: "伯基納法索",
        burundi: "布隆迪",
        cape_Verde: "佛得角",
        cayman_Islands: "開曼群島",
        central_African_Republic: "中非共和國",
        chad: "乍得",
        comoros: "科摩羅",
        the_Republic_of_the_Congo: "剛果（布）",
        democratic_Republic_of_the_Congo: "剛果（金）",
        djibouti: "吉佈提",
        ecuador: "厄瓜多尔",
        el_Salvador: "薩爾瓦多",
        equatorial_Guinea: "赤道幾內亞",
        eritrea: "厄立特里亚",
        fiji: "斐濟",
        gabon: "加蓬",
        gambia: "岡比亞",
        greenland: "格陵蘭",
        guatemala: "危地马拉",
        guinea: "幾內亞",
        haiti: "海地",
        isle_of_Man: "馬恩岛",
        cote_d_Ivoire: "科特迪瓦",
        jamaica: "牙買加",
        jordan: "約旦",
        lebanon: "黎巴嫩",
        lesotho: "萊索托",
        liberia: "利比里亚",
        libya: "利比亞",
        madagascar: "馬达加斯加",
        malawi: "馬拉维",
        maldives: "馬尔代夫",
        mali: "馬里",
        mauritania: "毛里塔尼亞",
        mauritius: "毛里求斯",
        mozambique: "莫桑比克",
        namibia: "納米比亞",
        nicaragua: "尼加拉瓜",
        republic_of_Niger: "尼日尔",
        north_Korea: "朝鮮",
        reunion: "留尼汪",
        san_Marino: "聖馬力諾",
        senegal: "塞內加爾",
        sierra_Leone: "塞拉利昂",
        somalia: "索馬里",
        sudan: "蘇丹",
        suriname: "蘇里南",
        eswatini: "斯威士兰",
        syria: "敘利亞",
        togo: "多哥",
        tonga: "汤加",
        tunisia: "突尼斯",
        united_States_Virgin_Islands: "美屬維爾京群島",
        uganda: "烏干達",
        uruguay: "烏拉圭",
        vatican: "梵蒂岡",
        yemen: "也门",
        yugoslavia: "南斯拉夫",
        zambia: "赞比亚",
        zimbabwe: "津巴布韦",
        china: "中國",
    }
};
