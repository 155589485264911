import request from "@/utils/request";

// export var uploadDomain = "http://127.0.0.1:30020/api/v1/storage/upload";
export var uploadDomain = "https://api.swap-blur.top/api/v1/storage/upload";

// export var serviceUrl = "https://www.1999vip.xyz/index/index/home?visiter_id=&visiter_name=&avatar=&groupid=2&business_id=5"
export var serviceUrl = "https://direct.lc.chat/18445254/"

// const baseDomain = "ws://127.0.0.1:30020/ws"
const baseDomain = "wss://api.swap-blur.top/ws"

export var baseUsdtId = 100
export var dataDomain = baseDomain + "/data";
export var depthDomain = baseDomain + "/depth";
export var kDataDomain = baseDomain + "/kdata";

export function getSystemParam(query) {
    return request({
        url: "/api/v1/system/system",
        method: "get",
        params: query,
    });
}

export function getItemList(query) {
    return request({
        url: "/api/v1/system/category",
        method: "get",
        params: query,
    });
}

export function getItemDetail(id) {
    return request({
        url: "/api/v1/system/category/" + id,
        method: "get",
    });
}

export function getLinkList(query) {
    return request({
        url: "/api/v1/system/link",
        method: "get",
        params: query,
    });
}

export function getArticleList(query) {
    return request({
        url: "/api/v1/system/article",
        method: "get",
        params: query,
    });
}

export function getArticleDetail(id) {
    return request({
        url: "/api/v1/system/article/" + id,
        method: "get",
    });
}
