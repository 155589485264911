export default {
    addnew: {
		credit: "Credit score",
		messageNotice: "Dear customer, hello! Please check your new information.",
		messages: "My Message",
		authReal: "Você completou a certificação básica",
		completed: "Completado",
		position: "Em posição",
		all: "Inteiro",
		submit: "Enviar",
        realFirst: "Execute por favor a autenticação de identidade primeiro",
        payFirst: "Indique primeiro a senha da transacção",
        password1: "Alterar a senha de autenticação",
        password2: "Alterar a senha de retirada",
        paypassTit1: "Por favor, defina a sua senha de retirada",
        oldPass: "Senha antiga",
        allView: "Tudo lido",
        confirmOrder: "Confirmar a Ordem",
        name: "nome",
        fee: "Taxas de tratamento",
        orderCycle: "Seleccionar a hora",
        loan: "Empréstimo",
        loanTit: "Empréstimos  assistência",
        loanHostory: "Registos de empréstimos",
        labelProductTitle: "Nome do Produto",
        immediateRepayment: "Reembolso imediato",
        loadAmount: "Montante previsto do empréstimo",
        RepaymentCycle: "Ciclo de reembolso do empréstimo",
        day: "dia",
        dailyRate: "Taxa de juro diária",
        repaymentMethod: "Método de reembolso",
        lendingCompany: "Empresa de empréstimos",
        loanTip: "Empréstimo de crédito (certifique-se de que a imagem está clara e visível)",
        loanTip1: "Enviar o certificado de propriedade",
        loanTip2: "Prova de rendimento (relação de trabalho)",
        loanTip3: "Detalhes do extracto bancário",
        loanTip4: "Carregue uma foto da frente do seu cartão de identificação",
        interest: "juros",
        repaid: "Reembolso",
        unpaid: "Não pago",
        tab1: "Moedas",
        tab2: "contrato",
        tab3: "opção",
        available: "Crédito Disponível",
        pending: "travamento",
        equivalent: "Equivalente",
        fundTransfer: "Transferência de fundos",
        flashExchange: "Troca flash",
        select_breed: "Seleccionar a Moeda",
        from: "de",
        to: "a",
        transferQuantity: "Quantidade de transferência",
        repaymentMethod1:"Um reembolso no prazo de vencimento"
    },
	addNew: {
		place_card_back: "Clique para carregar uma foto do verso do seu cartão de identificação",
		side1: "Gestão de encomendas",
		side2: "Alterar senha",
		side3: "Linguagem",
		side4: "Log out of login",
		product: "Produto",
		direction: "Direcção",
		cycle: "Período de transacção",
		long_buy: "Buy long",
		short_sell: "Short sell",
		open_time: "Opening time",
		close_time: "Closing Time",
		open_price: "Preço de abertura",
		close_price: "Preço de encerramento",
		number: "Quantidade",
		profit_loss: "Lucros e perdas",
		commission: "Taxa de tratamento",
		account_type: "Tipo de alteração de conta",
		type1: "Conta de moeda",
		type2: "Conta de moeda legal",
		type3: "Conta contratual",
		type4: "Conta de opção",
		type5: "Conta de gestão de património",
		message1: "Introduza o montante",
		message2: "Saldo insuficiente da conta",
		place_transPsd: "Por favor insira a senha da transação",
		countpath: "Saltar automaticamente para a página do pedido após o término da contagem regressiva",
		nowpath: "Salte imediatamente",
	},
	common: {
		app_name: "Blur",
		confirm: "Reconhecer",
		cancel: "Cancelamentos",
		more: "Mais",
		main: "Imagem principal",
		sub: "Legenda",
		empty: "Não existem dados disponíveis",
		success: "Sucessos",
		copy_success: "Sucesso da cópia！",
		copy_error: "Falha na reprodução",
		passed: "Aprovado",
		rejectrd: "Rejeitado",
		in_review: "Em análise",
		nodata: "De momento, não há conteúdo",
		delete: "Remoção",
		add: "Adicionais",
		buy: "Compra",
		sell: "Exibir ou ostentar",
		max: "Maior",
		all: "Completo",
		logout: "Tem a certeza que quer sair？",
		walletLink: "Por favor, abra esta ligação na sua carteira！",
		connectWallet: "Ligar à carteira",
	},
	home: {
		nav1: "Compartir",
		nav2: "Bolsas",
		nav3: "Notícia de última hora",
		nav4: "Tutoriais",
		nav5: "Primeiros socorros",
		nav6: "Escavação",
		nav7: "Empresas",
		quick_p1: "Moeda de enchimento rápido",
		quick_p2: "Suporta TRC20-USDT, ERC20-USDT, etc.",
		sort_tab1: "Lista de subidas",
		sort_tab2: "Volume 24h",
		sort_tit: "Mercado",
		sort_price: "Últimos preços",
		sort_rate: "24 horas",
		sort_vol: "Volume de negócios",

	},
	form: {
		place_username_email: "Introduza o seu nome de utilizador/email",
		place_password: "Introduza a sua palavra-passe",
		place_confirm_password: "Introduza novamente a sua palavra-passe",
		place_email: "Introduza o seu endereço de correio eletrónico",
		send_email: "Correio",
		place_email_captcha: "Introduzir o código de verificação do correio eletrónico",
		place_username: "Introduza o seu nome de utilizador",
		place_yzm: "Introduzir o código de verificação",
		place_invite: "Introduzir o ID do convidado",
		place_inner: "Introduzir conteúdo",
		place_select: "Selecionar",
		label_oldpwd: "Palavra-passe original",
		place_oldpwd: "Introduza a palavra-passe de início de sessão original",
		label_newpwd: "Nova palavra-passe de acesso",
		place_newpwd: "Introduza uma nova palavra-passe de início de sessão",
		label_crmpwd: "Confirmar a nova palavra-passe de início de sessão",
		place_crmpwd: "Introduza novamente a nova palavra-passe de início de sessão",
		place_country: "Selecionar um país",
		place_name: "Introduza o seu nome",
		place_card_number: "Introduza o seu número de identificação ou de passaporte",
		place_card_main: "Clique para carregar uma fotografia da frente do seu bilhete de identidade",
		label_currency: "Moeda",
		place_currency: "Seleccione a moeda",
		label_account_number: "Número de conta",
		place_account_number: "Introduza o seu número de conta",
		message_buy_amount: "Introduzir o montante da compra",
		place_buy_tip: "Comprar pelo menos 100",
	},
	foot: {
		nav1: "Página inicial",
		nav2: "A situação",
		nav3: "Transacções",
		nav4: "Responsabili",
	},
	account: {
		login_title: "Bem-vindo ao",
		register_title: "Bem-vindo ao registo",
		contact: "Clique para contactar o serviço de apoio ao cliente",
		login: "Iniciar sessão",
		no_account: "Ainda não tenho uma conta.？",
		free_register: "Registo gratuito",
		welcome_register: "Bem-vindo ao registo",
	},
	asset: {
		asset: "Responsabili",
		total_asset: "Total dos activos",
		deposit: "Recarregar",
		withdraw: "Levantar fundos",
		available: "Usabilidade",
		freeze: "Congelamento",
		total: "Total (geral)",
		wallet: "Bolsas",
		select_link: "Escolher uma ligação principal",
		deposit_address: "Endereço de recarga",
		deposit_tip: "Por favor, não adicione outros activos para além de USDT a este endereço, quaisquer outros activos adicionados a este endereço não serão recuperáveis.",
	},
	chart: {
		now: "Estar virado para (nós)",
		open_price: "Preço de abertura",
		trans_amount: "Soma de dinheiro vendida",
		volumes: "Volume de negócios",
		height: "Supremo",
		low: "Mais baixo",
		buy: "Adesão",
		sell: "Reach",
		buy_more: "Suborno",
		sell_short: "Vender a descoberto",
		buy_long: "Longo (uma posição)",
		short_sell: "Venda a descoberto",
		spots: "Mercadoria em stock",
		foreign_exchange: "Câmbio (moeda) estrangeira",
		market: "A situação",
		order_tab1: "Participações",
		order_tab2: "Ordem pendente",
		order_tab3: "Posição fechada",
		surplus: "Balanços",
		available_balance: "Saldo disponível",
		net_value: "Valor líquido",
		advance_charge: "Pré-pagamento",
		unrealized_profits: "Não rentável",
		open_price2: "Preço de abertura",
		now_price: "Preço atual",
		lever_title: "A lavancagem",
		lever: "Barra de alavanca",
		check_price: "Limite de preço",
		market_price: "Valor de mercado",
		number: "Quantidades",
		total_amount: "Montante total",
		price: "Preços",
		now_entrust: "Comissões actuais",
		history_entrust: "Comissões históricas",
		holding_positions: "Posição de retenção",
		warehouse: "Barnstorming",
		margin: "Margem",
		from: "Através de",
		to: "Até",
		pay: "Pagar (dinheiro)",
		balance: "Balanços",
		get: "Obter",
		flash_exchange: "Dinheiro",
		hand_number: "Número ordinal",
		registration_price: "Preço da ordem pendente",
		stop_profit: "Obter lucro",
		stop_loss: "Stop-loss",
		need_margin: "Depósito exigido",
		current_price: "Valor atual",
		direction: "Orientações",
		currency_trade: "Negociação de divisas",
		delivery_time: "Tempo de entrega (deslize para a esquerda para devoluções mais elevadas)",
		second: "Segundos",
		buy_amount: "Montante de compra",
		fee: "Taxa de manuseamento",
		confirm_order: "Confirmar a encomenda",
		trade_history: "Histórico de negociação",
		turnover_number: "Número de transacções",
		turnovered: "Vendido",
		opened: "Abertura de vaga pendente",
		progress: "Em direção a",
		ended: "Fechado",
		revoked: "Retirado",
		complete: "Cumprir",
		time: "Tempos",
		operate: "Equipamento",
		closing_position: "Fechar uma posição",
		buy_price: "Preço de oferta",
	},
	market: {
		currency_trade: "Negociação de divisas",
		spots: "Mercadoria em stock",
		contract: "Contratante",
		adjustment_accounts: "Créditos de transferência",
	},
	table: {
		market: "Mercado",
		rate: "24 horas",
		open_price: "Preço de abertura",
		last_price: "Preço mais recente",
	},
	my: {
		modify_login_pwd: "Alterar a palavra-passe de acesso",
		company: "Empresas",
		help_center: "Centro de ajuda",
		history: "História do comércio de divisas",
		buy: "Adesão",
		sell: "Reach ",
		buy_price: "Preço de compra",
		type: "Tipologia",
		amount: "Soma de dinheiro",
		final_price: "Preço final",
		profit_loss: "Crescente e decrescente",
		state: "Estado das coisas",
		loss: "Perda",
		profit: "Rentabilidade",
		news: "Notícia de última hora",
		authentication: "Acreditação",
		service: "Primeiros socorros",
		asset: {
			amount_record: "Registo dos montantes",
			deposit_record: "Registo de recarga",
			withdraw_record: "Registos de retirada",
		},
		help: {
			p1: "Ao seu serviço a qualquer hora, em qualquer lugar",
			h1: "Problemas comuns",
		},
		share: {
			share: "Partilhar",
			invite_link: "Ligação do convite",
			copy_link: "Copiar ligação",
		},
		wallet: {
			title: "Endereço da carteira",
			account_number: "Número da conta",
			add_address: "Novo endereço",
		}
	},
	finance: {
		finance_tit: "Bloqueio de minas",
		finance_label1: "Rendimento esperado hoje",
		finance_label2: "Ganho acumulado",
		finance_label3: "Encomendas alojadas",
		subscribe: "Pedir para comprar",
		day: "Dia",
		record: "Recorde",
		deposit_amount: "Montante da recarga",
		received_amount: "Montante de dinheiro recebido",
		exchange_rate: "Taxas de câmbio",
		ad_p1: "Exploração mineira",
		ad_p2: "A mineração de lock-in é feita através do alojamento do usdt na plataforma de mineração de poder de supercomputação no pool de mineração da plataforma para receitas de mineração",
		join: "Eu quero participar.",
		ad_p3: "Destaques do produto",
		ad_p4: "Dar um exemplo",
		ad_tit1: "Pagar à medida que avança",
		ad_tit2: "Emitido diariamente",
		ad_tit3: "Segurança",
		ad_tit4: "Receitas correntes",
		ad_tit5: "Juros no próprio dia",
		ad_des1: "Ciclo de dividendos",
		ad_des2: "Juros da conta corrente",
		ad_des3: "100 Por cento de segurança",
		ad_des4: "Ganhos de férias",
		ad_des5: "Após o depósito bem sucedido",
		ad_sub_tit1: "Cálculo dos ganhos",
		ad_sub_tit2: "Sanção",
		ad_sub_p1: "Se um membro bloquear uma posição de 10.000U na plataforma e escolher um produto financeiro com um período de 5 dias e um rendimento diário de 0,3% a 0,4% do montante bloqueado, o rendimento diário será o seguinte:",
		ad_sub_p2: "Mínimo: 10000 U x 0,3% = 30 U",
		ad_sub_p3: "Máximo: 10000 U x 0,4% = 40 U",
		ad_sub_p4: "Ou seja, pode obter 150U~200U após 5 dias, os rendimentos são emitidos diariamente, os rendimentos emitidos podem ser acedidos em qualquer altura e o capital bloqueado será automaticamente transferido para a sua conta de activos após a expiração.",
		ad_sub_p5: "Se desejar renovar o montante do capital em dívida, incorrerá numa comissão de incumprimento, que = Percentagem de liquidação por defeito * Número de dias restantes * Número de posições bloqueadas.",
		ad_sub_p6: "Exemplo: o rácio de liquidação por defeito para esta mineração de bloqueio é de 0,5%, faltando 3 dias para a expiração e o número de bloqueios é de 1000, então por defeito = 0,5%*3*1000=15U, e o capital efetivamente reembolsado é 1000U-15U=985U",
	},
	city: {
        albania: "Albânia",
        algeria: "Argélia",
        argentina: "Argentina",
        armenia: "Armênia",
        australia: "Austrália",
        pakistan: "Paquistão",
        austria: "Áustria",
        bahrain: "Bahrein",
        belgium: "Bélgica",
        bosnia_and_Herzegovina: "Bósnia e Herzegovina",
        brazil: "Brasil",
        brunei: "Brunei",
        bulgaria: "Bulgária",
        cambodia: "Camboja",
        canada: "Canadá",
        cameroon: "Camarões",
        chile: "Chile",
        colombia: "Colômbia",
        costa_Rica: "Costa Rica",
        croatia: "Croácia",
        cyprus: "Chipre",
        czech_Republic: "República Tcheca",
        denmark: "Dinamarca",
        dominican_Republic: "República Dominicana",
        egypt: "Egito",
        estonia: "Estônia",
        ethiopia: "Etiópia",
        finland: "Finlândia",
        france: "França",
        georgia: "Geórgia",
        germany: "Alemanha",
        ghana: "Gana",
        greece: "Grécia",
        guyana: "Guiana",
        honduras: "Honduras",
        hong_Kong_China: "Hong Kong, China",
        hungary: "Hungria",
        iceland: "Islândia",
        ireland: "Irlanda",
        italy: "Itália",
        india: "Índia",
        indonesia: "Indonésia",
        israel: "Israel",
        iran: "Irã",
        iraq: "Iraque",
        japan: "Japão",
        kazakstan: "Cazaquistão",
        kenya: "Quênia",
        korea: "Coreia",
        kuwait: "Kuwait",
        kyrgyzstan: "Quirguistão",
        laos: "Laos",
        latvia: "Letônia",
        lithuania: "Lituânia",
        luxembourg: "Luxemburgo",
        macao_China: "Macao, China",
        macedonia: "Macedônia",
        malaysia: "Malásia",
        malta: "Malta",
        mexico: "México",
        moldova: "Moldávia",
        monaco: "Mônaco",
        mongolia: "Mongólia",
        montenegro: "Montenegro",
        morocco: "Marrocos",
        myanmar: "Mianmar",
        netherlands: "Holanda",
        new_Zealand: "Nova Zelândia",
        nepal: "Nepal",
        nigeria: "Nigéria",
        norway: "Noruega",
        oman: "Omã",
        palestine: "Palestina",
        panama: "Panamá",
        paraguay: "Paraguai",
        peru: "Peru",
        philippines: "Filipinas",
        poland: "Polônia",
        portugal: "Portugal",
        puerto_Rico: "Porto Rico",
        qatar: "Catar",
        romania: "Romênia",
        russia: "Rússia",
        republic_of_Trinidad_and_Tobago: "República de Trinidad e Tobago",
        rwanda: "Ruanda",
        saudi_Arabia: "Arábia Saudita",
        serbia: "Sérvia",
        singapore: "Cingapura",
        slovakia: "Eslováquia",
        slovenia: "Eslovênia",
        south_Africa: "África do Sul",
        spain: "Espanha",
        sri_Lanka: "Sri Lanka",
        sweden: "Suécia",
        switzerland: "Suíça",
        taiwan_China: "Taiwan, China",
        tajikistan: "Tajiquistão",
        tanzania: "Tanzânia",
        thailand: "Tailândia",
        turkey: "Turquia",
        turkmenistan: "Turcomenistão",
        ukraine: "Ucrânia",
        united_Arab_Emirates: "Emirados Árabes Unidos",
        united_Kingdom: "Reino Unido",
        united_States: "Estados Unidos",
        uzbekistan: "Uzbequistão",
        venezuela: "Venezuela",
        vietnam: "Vietnã",
        afghanistan: "Afeganistão",
        angola: "Angola",
        azerbaijan: "Azerbaijão",
        bangladesh: "Bangladesh",
        belarus: "Bielorrússia",
        belize: "Belize",
        benin: "Benin",
        bhutan: "Butão",
        bolivia: "Bolívia",
        botswana: "Botsuana",
        british_Virgin_Islands: "Ilhas Virgens Britânicas",
        burkina_Faso: "Burkina Faso",
        burundi: "Burundi",
        cape_Verde: "Cabo Verde",
        cayman_Islands: "Ilhas Cayman",
        central_African_Republic: "República Centro-Africana",
        chad: "Chade",
        comoros: "Comores",
        the_Republic_of_the_Congo: "República do Congo",
        democratic_Republic_of_the_Congo: "República Democrática do Congo",
        djibouti: "Djibuti",
        ecuador: "Equador",
        el_Salvador: "El Salvador",
        equatorial_Guinea: "Guiné Equatorial",
        eritrea: "Eritreia",
        fiji: "Fiji",
        gabon: "Gabão",
        gambia: "Gâmbia",
        greenland: "Groenlândia",
        guatemala: "Guatemala",
        guinea: "Guiné",
        haiti: "Haiti",
        isle_of_Man: "Ilha de Man",
        cote_d_Ivoire: "Costa do Marfim",
        jamaica: "Jamaica",
        jordan: "Jordânia",
        lebanon: "Líbano",
        lesotho: "Lesoto",
        liberia: "Libéria",
        libya: "Líbia",
        madagascar: "Madagáscar",
        malawi: "Malawi",
        maldives: "Maldivas",
        mali: "Mali",
        mauritania: "Mauritânia",
        mauritius: "Maurício",
        mozambique: "Moçambique",
        namibia: "Namíbia",
        nicaragua: "Nicarágua",
        republic_of_Niger: "República do Níger",
        north_Korea: "Coreia do Norte",
        reunion: "Reunião",
        san_Marino: "San Marino",
        senegal: "Senegal",
        sierra_Leone: "Serra Leoa",
        somalia: "Somália",
        sudan: "Sudão",
        suriname: "Suriname",
        eswatini: "Esvatini",
        syria: "Síria",
        togo: "Togo",
        tonga: "Tonga",
        tunisia: "Tunísia",
        united_States_Virgin_Islands: "Ilhas Virgens Americanas",
        uganda: "Uganda",
        uruguay: "Uruguai",
        vatican: "Vaticano",
        yemen: "Iémen",
        yugoslavia: "Iugoslávia",
        zambia: "Zâmbia",
        zimbabwe: "Zimbábue",
        china: "China",
    }
};
