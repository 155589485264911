export default {
    addnew: {
		credit: "Credit score",
		messageNotice: "Dear customer, hello! Please check your new information.",
		messages: "My Message",
		authReal: "기본 인증 완료",
		completed: "완료됨",
		position: "창고 보유 중",
		all: "모두",
		submit: "제출",
        realFirst: "먼저 인증하십시오.",
        payFirst: "먼저 거래 비밀번호를 설정하십시오.",
        password1: "로그인 암호 수정",
        password2: "인출 비밀번호 수정",
        paypassTit1: "인출 비밀번호를 설정하십시오.",
        oldPass: "이전 암호",
        allView: "모두 읽음",
        confirmOrder: "주문 확인",
        name: "이름",
        fee: "수수료",
        orderCycle: "시간 선택",
        loan: "대출",
        loanTit: "조력 대출",
        loanHostory: "대출 기록",
        labelProductTitle: "제품 이름",
        immediateRepayment: "즉시 상환하다",
        loadAmount: "예상 대출 금액",
        RepaymentCycle: "대출 상환 주기",
        day: "일",
        dailyRate: "일일 이율",
        repaymentMethod: "상환 방식",
        lendingCompany: "대출 회사",
        loanTip: "신용 대출 (그림이 잘 보이는지 확인하십시오)",
        loanTip1: "부동산 증서를 올리다",
        loanTip2: "소득증명(고용관계)",
        loanTip3: "은행 명세서 상세 정보",
        loanTip4: "주민등록증 정면 사진 올리기",
        interest: "이익",
        repaid: "이미 상환",
        unpaid: "미상환",
        tab1: "코인",
        tab2: "계약",
        tab3: "옵션",
        available: "가용 한도",
        pending: "잠금",
        equivalent: "환산",
        fundTransfer: "자금 이전",
        flashExchange: "환전",
        select_breed: "통화 선택",
        from: "부터",
        to: "다음",
        transferQuantity: "전송 수량",
        repaymentMethod1: "만기가 되면 한 번 상환한다."
    },
	addNew: {
		place_card_back: "신분증 뒷모습 업로드 클릭",
		side1: "주문 관리",
		side2: "암호 수정",
		side3: "언어",
		side4: "로그인 종료",
		product: "제품",
		direction: "방향",
		cycle: "거래 기간",
		long_buy: "많이 사세요",
		short_sell: "공매",
		open_time: "창고 오픈 시간",
		close_time: "창고 정리 시간",
		open_price: "창고 개설 가격",
		close_price: "평가 가격",
		number: "수량",
		profit_loss: "손익",
		commission: "수수료",
		account_type: "장부 유형",
		type1: "코인 계좌",
		type2: "법화 계좌",
		type3: "계약 계정",
		type4: "옵션 계정",
		type5: "재테크 계",
		message1: "금액을 입력하십시오",
		message2: "계좌 잔액 부족",
		place_transPsd: "거래 암호를 입력하십시오.",
		countpath: "카운트다운 종료 후 주문 페이지로 자동 이동",
		nowpath: "즉시 점프",
	},
	common: {
		app_name: "Blur",
		confirm: "확인",
		cancel: "취소",
		more: "추가",
		main: "기본 그림",
		sub: "부도",
		empty: "데이터 없음",
		success: "성공",
		copy_success: "복제 성공！",
		copy_error: "복제 실패！",
		passed: "통과",
		rejectrd: "거부됨",
		in_review: "심사 중",
		nodata: "잠시 내용이 없습니다.",
		delete: "삭제",
		add: "신규",
		buy: "사다",
		sell: "팔다",
		max: "최대",
		all: "모두",
		logout: "로그아웃하시겠습니까?",
		walletLink: "지갑에서 이 연결을 켜십시오！",
		connectWallet: "지갑 연결",
	},
	home: {
		nav1: "공유",
		nav2: "지갑",
		nav3: "속보",
		nav4: "자습서",
		nav5: "고객 서비스",
		nav6: "채굴",
		nav7: "회사",
		quick_p1: "빠른 충전",
		quick_p2: "TRC20-USDT, ERC20-USDT 등 지원",
		sort_tab1: "상승폭 순위",
		sort_tab2: "24h 거래량",
		sort_tit: "시장",
		sort_price: "최신 가격",
		sort_rate: "24h 상승 폭",
		sort_vol: "거래량",

	},
	form: {
		place_username_email: "사용자 이름 / 사서함 을 입력하십시오.",
		place_password: "암호를 입력하십시오.",
		place_confirm_password: "암호를 다시 입력하십시오.",
		place_email: "메일박스 입력",
		send_email: "메일 보내기",
		place_email_captcha: "메일박스 인증번호를 입력하십시오.",
		place_username: "사용자 이름을 입력하십시오.",
		place_yzm: "인증 코드를 입력하십시오.",
		place_invite: "초대자 ID를 입력하십시오.",
		place_inner: "내용을 입력하십시오.",
		place_select: "선택하십시오.",
		label_oldpwd: "원래 로그인 암호",
		place_oldpwd: "원래 로그인 암호를 입력하십시오.",
		label_newpwd: "새 로그인 암호",
		place_newpwd: "새 로그인 암호를 입력하십시오.",
		label_crmpwd: "새 로그인 암호 확인",
		place_crmpwd: "새 로그인 암호를 다시 입력하십시오.",
		place_country: "국가를 선택하세요",
		place_name: "이름을 입력하십시오.",
		place_card_number: "주민등록번호나 여권번호를 입력하십시오.",
		place_card_main: "클릭하여 신분증 정면 사진 업로드",
		label_currency: "화폐",
		place_currency: "화폐를 선택하세요",
		label_account_number: "계좌번호",
		place_account_number: "계좌번호 입력하세요",
		message_buy_amount: "구매 금액을 입력하십시오.",
		place_buy_tip: "최소 100달러 매입",
	},

	foot: {
		nav1: "홈 페이지",
		nav2: "시세",
		nav3: "거래",
		nav4: "자산",
	},
	account: {
		login_title: "어서 오세요",
		register_title: "등록 시작",
		contact: "클릭하여 고객센터에 연락",
		login: "로그인",
		no_account: "아직 계정이 없습니다.？",
		free_register: "무료 등록",
		welcome_register: "등록 시작",
	},
	asset: {
		asset: "자산",
		total_asset: "총자산",
		deposit: "충전",
		withdraw: "현금 인출",
		available: "사용 가능",
		freeze: "동결",
		total: "합계",
		wallet: "지갑",
		select_link: "마스터 체인 선택",
		deposit_address: "충전 주소",
		deposit_tip: "이 주소에 USDT 이외의 자산을 충전하지 마십시오. 이 주소에 충전된 다른 자산은 찾을 수 없습니다.",
	},
	chart: {
		now: "현재",
		open_price: "개장가",
		trans_amount: "거래 금액",
		volumes: "거래량",
		height: "최고",
		low: "최소",
		buy: "매입",
		sell: "매도",
		buy_more: "많이 사다",
		sell_short: "공매",
		buy_long: "많이 하다",
		short_sell: "비우다",
		spots: "현물",
		foreign_exchange: "외화",
		market: "시세",
		order_tab1: "창고 보유",
		order_tab2: "청구서",
		order_tab3: "창고 정리됨",
		surplus: "잔고",
		available_balance: "사용 가능한 잔액",
		net_value: "순가치",
		advance_charge: "선불",
		unrealized_profits: "수익 미실현",
		open_price2: "창고 개설 가격",
		now_price: "현재 가격",
		lever_title: "조정 레버",
		lever: "레버",
		check_price: "제한 가격",
		market_price: "시가",
		number: "수량",
		total_amount: "총 금액",
		price: "가격",
		now_entrust: "현재 위임",
		history_entrust: "역사적 의뢰",
		holding_positions: "창고를 보유하다",
		warehouse: "창고마다",
		margin: "보증금",
		from: "부터",
		to: "다음",
		pay: "지불",
		balance: "잔액",
		get: "획득",
		flash_exchange: "환전",
		hand_number: "손수",
		registration_price: "단가를 걸다",
		stop_profit: "그치다",
		stop_loss: "손실 방지",
		need_margin: "보증금을 지불해야 한다",
		current_price: "현가",
		direction: "방향",
		currency_trade: "화폐 거래",
		delivery_time: "교차 시간 (왼쪽에서 미끄러지면 수익이 더 높음)",
		second: "초",
		buy_amount: "매입 금액",
		fee: "수수료",
		confirm_order: "주문 확인",
		trade_history: "거래 내역",
		turnover_number: "거래 수량",
		turnovered: "거래 완료",
		opened: "창고 개설을 기다리다",
		progress: "진행 중",
		ended: "종료",
		revoked: "취소됨",
		complete: "완료",
		time: "시간",
		operate: "작업",
		closing_position: "창고 정리",
		buy_price: "매입가",
	},
	market: {
		currency_trade: "화폐 거래",
		spots: "현물",
		contract: "계약",
		adjustment_accounts: "장부 조정",
	},
	table: {
		market: "시장",
		rate: "24h 상승 폭",
		open_price: "개장가",
		last_price: "최신 가격",
	},
	my: {
		modify_login_pwd: "로그인 암호 수정",
		company: "회사",
		help_center: "도움말 센터",
		history: "통화 거래 내역",
		buy: "매입",
		sell: "매도",
		buy_price: "매입 가격",
		type: "유형",
		amount: "금액",
		final_price: "최종 가격",
		profit_loss: "손익",
		state: "상태",
		loss: "결손",
		profit: "이익",
		news: "속보",
		authentication: "인증",
		service: "고객 서비스",
		asset: {
			amount_record: "금액 기록",
			deposit_record: "충전 기록",
			withdraw_record: "현금 인출 기록",
		},
		help: {
			p1: "언제 어디서나 서비스 제공",
			h1: "자주 묻는 질문",
		},
		share: {
			share: "공유",
			invite_link: "초대 링크",
			copy_link: "링크 복사",
		},
		wallet: {
			title: "지갑 주소",
			account_number: "계좌번호",
			add_address: "새 주소",

		}
	},
	finance: {
		finance_tit: "창고를 잠그고 채굴하다.",
		finance_label1: "오늘의 수익 예상",
		finance_label2: "누적 이익",
		finance_label3: "관리되는 주문",
		subscribe: "요청서",
		day: "일",
		record: "기록",
		deposit_amount: "충전 금액",
		received_amount: "입금 금액",
		exchange_rate: "환율",
		ad_p1: "채굴로 끊임없이 돈을 벌다.",
		ad_p2: "자물쇠 창고 채굴은 usdt를 플랫폼 초산력 광기에 위탁하여 플랫폼 광산에서 채굴 수익을 진행하는 것이다",
		join: "참여할 거야",
		ad_p3: "제품 특징",
		ad_p4: "예를 들다",
		ad_tit1: "저장된 대로 가져오기",
		ad_tit2: "매일 발송",
		ad_tit3: "안전 보장",
		ad_tit4: "수익이 끊이지 않다",
		ad_tit5: "당일이자",
		ad_des1: "배당 주기",
		ad_des2: "당좌 이자",
		ad_des3: "100% 보안",
		ad_des4: "공휴일 이익",
		ad_des5: "저장 성공 후",
		ad_sub_tit1: "이익 계산",
		ad_sub_tit2: "위약금",
		ad_sub_p1: "회원은 플랫폼에서 10000U의 창고를 잠그고 주기가 5일이고 일산이 창고를 잠그는 금액의 0.3~0.4% 의 재테크상품을 선택하면 매일 다음과 같이 산출된다.",
		ad_sub_p2: "최저: 10000U x 0.3% = 30U",
		ad_sub_p3: "최대: 10000U x 0.4% = 40U",
		ad_sub_p4: "즉 5일 후 150U~200U의 수익을 얻을 수 있으며, 수익은 매일 하달되며, 하달된 수익은 수시로 입출금할 수 있으며, 창고 잠금 원금이 만기가 되면 자동으로 당신의 자산 계좌로 이체됩니다.",
		ad_sub_p5: "만기가 도래하지 않은 원금을 이체하고자 하는 경우 위약금이 발생하며 위약금 = 위약결산비율 * 잔여일수 * 잠금수량이 발생합니다.",
		ad_sub_p6: "예: 이 자물쇠 창고 채굴의 위약 결산 비율은 0.5% 이고, 남은 3일 만기가 도래하며, 자물쇠 창고 수량이 1000이면 위약금 = 0.5% * 3 * 1000 = 15U, 실제 반환 원금은 1000U-15U = 985U",
	},
    city: {
        albania: "알바니아",
        algeria: "알제리",
        argentina: "아르헨티나",
        armenia: "아르메니아",
        australia: "호주",
        pakistan: "파키스탄",
        austria: "오스트리아",
        bahrain: "바레인",
        belgium: "벨기에",
        bosnia_and_Herzegovina: "보스니아 헤르체고비나",
        brazil: "브라질",
        brunei: "브루나이",
        bulgaria: "불가리아",
        cambodia: "캄보디아",
        canada: "캐나다",
        cameroon: "카메룬",
        chile: "칠레",
        colombia: "콜롬비아",
        costa_Rica: "코스타리카",
        croatia: "크로아티아",
        cyprus: "키프로스",
        czech_Republic: "체코",
        denmark: "덴마크",
        dominican_Republic: "도미니카",
        egypt: "이집트",
        estonia: "에스토니아",
        ethiopia: "에티오피아",
        finland: "핀란드",
        france: "프랑스",
        georgia: "그루지야",
        germany: "독일",
        ghana: "가나",
        greece: "그리스",
        guyana: "가이아나",
        honduras: "온두라스",
        hong_Kong_China: "중국 홍콩",
        hungary: "헝가리",
        iceland: "아이슬란드",
        ireland: "아일랜드",
        italy: "이탈리아",
        india: "인도",
        indonesia: "인도네시아",
        israel: "이스라엘",
        iran: "이란",
        iraq: "이라크",
        japan: "일본",
        kazakstan: "카자흐스탄",
        kenya: "케냐",
        korea: "한국",
        kuwait: "쿠웨이트",
        kyrgyzstan: "키르기스스탄",
        laos: "라오스",
        Latvia: "라트비아",
        lithuania: "리투아니아",
        luxembourg: "룩셈부르크",
        macao_China: "중국 마카오",
        macedonia: "마케도니아",
        malaysia: "말레이시아",
        malta: "몰타",
        mexico: "멕시코",
        moldova: "몰도바",
        monaco: "모나코",
        mongolia: "몽골",
        montenegro: "몬테네그로",
        morocco: "모로코",
        myanmar: "미얀마",
        netherlands: "네덜란드",
        new_Zealand: "뉴질랜드",
        nepal: "네팔",
        nigeria: "나이지리아",
        norway: "노르웨이",
        oman: "오만",
        palestine: "팔레스타인",
        panama: "파나마",
        paraguay: "파라과이",
        peru: "페루",
        philippines: "필리핀",
        poland: "폴란드",
        portugal: "포르투갈",
        puerto_Rico: "푸에르토리코",
        qatar: "카타르",
        romania: "루마니아",
        russia: "러시아",
        republic_of_Trinidad_and_Tobago: "트리니다드 토바고",
        rwanda: "르완다",
        saudi_Arabia: "사우디아라비아",
        serbia: "세르비아",
        singapore: "싱가포르",
        slovakia: "슬로바키아",
        slovenia: "슬로베니아",
        south_Africa: "남아프리카",
        spain: "스페인",
        sri_Lanka: "스리랑카",
        sweden: "스웨덴",
        switzerland: "스위스",
        taiwan_China: "대만",
        tajikistan: "타지키스탄",
        tanzania: "탄자니아",
        thailand: "태국",
        turkey: "터키",
        turkmenistan: "투르크메니스탄",
        ukraine: "영국",
        united_Arab_Emirates: "미국",
        united_Kingdom: "우즈베키스탄",
        united_States: "베네수엘라",
        uzbekistan: "베트남",
        venezuela: "아프가니스탄",
        vietnam: "베트남",
        afghanistan: "아프가니스탄",
        angola: "앙골라",
        azerbaijan: "아제르바이잔",
        bangladesh: "방글라데시",
        belarus: "벨라루스",
        belize: "벨리즈",
        benin: "베냉",
        bhutan: "부탄",
        bolivia: "볼리비아",
        botswana: "보츠와나",
        british_Virgin_Islands: "영국령 버진 아일랜드",
        burkina_Faso: "부르키나파소",
        burundi: "부룬디",
        cape_Verde: "카보베르데",
        cayman_Islands: "케이맨 제도",
        central_African_Republic: "중앙아프리카",
        chad: "차드",
        comoros: "코모로",
        the_Republic_of_the_Congo: "콩고 (부)",
        democratic_Republic_of_the_Congo: "콩고 (김)",
        djibouti: "지부티",
        ecuador: "에콰도르",
        el_Salvador: "엘살바도르",
        equatorial_Guinea: "적도 기니",
        eritrea: "에리트레아",
        fiji: "피지",
        gabon: "가봉",
        gambia: "감비아",
        greenland: "그린란드",
        guatemala: "과테말라",
        guinea: "기니",
        haiti: "아이티",
        isle_of_Man: "맨 섬",
        cote_d_Ivoire: "코트디부아르",
        jamaica: "자메이카",
        jordan: "요르단",
        lebanon: "레바논",
        lesotho: "레소토",
        liberia: "라이베리아",
        libya: "리비아",
        madagascar: "마다가스카르",
        malawi: "말라위",
        maldives: "몰디브",
        mali: "말리",
        mauritania: "모리타니",
        mauritius: "모리셔스",
        mozambique: "모잠비크",
        namibia: "나미비아",
        nicaragua: "니카라과",
        republic_of_Niger: "니제르",
        north_Korea: "조선",
        reunion: "레위니옹",
        san_Marino: "산마리노",
        senegal: "세네갈",
        sierra_Leone: "시에라리온",
        somalia: "소말리아",
        sudan: "수단",
        suriname: "수리남",
        eswatini: "스와질란드",
        syria: "시리아",
        togo: "토고",
        tonga: "통가",
        tunisia: "튀니지",
        united_States_Virgin_Islands: "미국령 버진 아일랜드",
        uganda: "우간다",
        uruguay: "우루과이",
        vatican: "바티칸",
        yemen: "예멘",
        yugoslavia: "유고슬라비아",
        zambia: "잠비아",
        zimbabwe: "짐바브웨",
        china: "중국",
      }
};
