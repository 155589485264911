export default {
    addnew: {
		credit: "Credit score",
		messageNotice: "Dear customer, hello! Please check your new information.",
		messages: "My Message",
		authReal: "आप मूल प्रमाणपत्र पूरा किया है",
		completed: "पूर्ण",
		position: "स्थिति में",
		all: "पूर्ण",
		submit: "भेजें",
        realFirst: "कृपया पहिले परिचय प्रमाणीकरण करें",
        payFirst: "कृपया पहले हटाने के पासवर्ड सेट करें",
        password1: "लॉगइन पासवर्ड बदलें",
        password2: "पासवर्ड बदलें",
        paypassTit1: "कृपया अपना पासवर्ड नियत करें",
        oldPass: "मौलिक पासवर्ड प्रविष्ट करें",
        allView: "सभी पढ़ें",
        confirmOrder: "क्रम पुष्टि करें",
        name: "नाम",
        fee: "फीज हैंडलिंग",
        orderCycle: "समय चुनें",
        loan: "समय चुनें",
        loanTit: "ब्याज की सहायता कर रहा है",
        loanHostory: "लॉन रेकॉर्ड",
        labelProductTitle: "उत्पादन नाम",
        immediateRepayment: "तुरंत प्रतिक्रिया",
        loadAmount: "अपेक्षित लान मात्रा",
        RepaymentCycle: "लॉन पुनरावृत्ति साइकल",
        day: "दिन",
        dailyRate: "दिनांक व्याज दर",
        repaymentMethod: "पुनरावृत्ति विधि",
        lendingCompany: "लॉन कंपनी",
        loanTip: "क्रेडिट लॉन (कृपया निश्चित करें कि छवि स्पष्ट और दृश्यात्मक है)",
        loanTip1: "गुण प्रमाणपत्र अपलोड करें",
        loanTip2: "आगत का प्रमाण (काम संबंध)",
        loanTip3: "बैंक स्टेटेंशन विवरण",
        loanTip4: "अपने आईडी कार्ड के आगे की फोटो अपलोड करें",
        interest: "interest",
        repaid: "पुनरावृत्ति",
        unpaid: "बिना पैसा",
        tab1: "कोइन्स",
        tab2: "कंट्रांट",
        tab3: "विकल्प",
        available: "उपलब्ध क्रीडिट",
        pending: "ताला लगाता है",
        equivalent: "बराबर",
        fundTransfer: "फ़ंड स्थानान्तरण",
        flashExchange: "फ्लैश बदलें",
        select_breed: "मुद्रा चुनें",
        from: "से",
        to: "में",
        transferQuantity: "स्थानान्तरण मात्रा",
        repaymentMethod1:"एक बार दोहराएँ"
    },
	addNew: {
		place_card_back: "अपने आईडी कार्ड के पीछे फोटो को अपलोड करने के लिए क्लिक करें",
		side1: "क्रम प्रबंधन",
		side2: "पासवर्ड बदलें",
		side3: "भाषा",
		side4: "लॉग आउट और लगइन",
		product: "उत्पादन",
		direction: "दिशा",
		cycle: "ट्रांसेक्शन अवधि के दौरान",
		long_buy: "लंबा खरीद करें",
		short_sell: "छोटा विक्रेत",
		open_time: "समय खोलें",
		close_time: "समय बन्द कर रहा है",
		open_price: "मूल्य खोल रहा है",
		close_price: "बन्द दर",
		number: "मात्रा",
		profit_loss: "प्रोफाइट और हराम",
		commission: "फीज हैंडलिंग",
		account_type: "Account change type",
		type1: "खाता",
		type2: "मुद्रा खाता फिट करें",
		type3: "संपर्क खाता",
		type4: "विकल्प खाता",
		type5: "सम्पत्ति प्रबंधन खाता",
		message1: "कृपया मात्रा भरें",
		message2: "अपर्याप्त खाता संतुलन",
		place_transPsd: "कृपया ट्रांसेक्शन पासवर्ड भरें",
		countpath: "गणना डाउन समाप्त होने के बाद स्वचालित रूप से क्रम पृष्ठ पर जाएँ",
		nowpath: "तुरंत जाओ",
	},
	billTypes: [
		{v: 101, name: 'फिर चार्ज करें'},
		{v: 102, name: 'डिपोस्ट'},
		{v: 103, name: 'जल्दीweather forecast'},
		{v: 104, name: 'टाविंग'},
		{v: 105, name: 'डिपोस्ट'},
		{v: 106, name: 'डिपोस्ट'},
		{v: 201, name: 'हटाएँ'},
		{v: 202, name: 'विडडवाल'},
		{v: 203, name: 'विडडवाल सफलता'},
		{v: 204, name: 'वाटडवाल असफल'},
		{v: 205, name: 'हटवाल फीज़'},
		{v: 206, name: 'स्थानान्तरण'},
		{v: 207, name: 'स्थानान्तरण'},
		{v: 208, name: 'मुद्रा स्थानान्तरण बाहर'},
		{v: 209, name: 'मुद्रा स्थानान्तरण'},
		{v: 301, name: 'मुद्रा हैंडलिंग फी'},
		{v: 302, name: 'आयात कंट्रैक्ट करें'},
		{v: 303, name: 'कंट्राक्ट खोलें'},
		{v: 311, name: 'विकल्प'},
		{v: 312, name: 'विकल्प वापस होता है'},
		{v: 313, name: 'विकल्प पुनरावृत्ति'},
		{v: 314, name: 'विकल्प हैंडलिंग फी'},
		{v: 315, name: 'मुद्रा'},
		{v: 316, name: 'मुद्रा'},
		{v: 317, name: 'कोइन खरीद लौटाना'},
		{v: 318, name: 'प्राप्त कोइन्स का खरीदान'},
		{v: 319, name: 'प्राप्त कोइन्स का खरीदान'},
		{v: 320, name: 'जल्दी कोइन विक्रिया जा रहा है'},
		{v: 321, name: 'मुद्रा'},
		{v: 322, name: 'कोइन विक्रेता परित्याग'},
		{v: 323, name: 'खाता में बेचा गया कोइन्स'},
		{v: 324, name: 'खाता में बेचा गया कोइन्स'},
		{v: 325, name: 'मुद्रा हैंडलिंग फी'},
		{v: 401, name: 'मिनिंग मशीन जोड़ें'},
		{v: 402, name: 'मिनिंग मशीन वापस'},
		{v: 403, name: 'मिनिंग मशीनरी आगत'},
		{v: 404, name: 'मिनिंग मशीन बाहर होना'},
		{v: 405, name: 'मिनिंग मशीन हैंडलिंग फी'},
		{v: 501, name: 'ब्लैंड बाक्स खरीद'},
		{v: 502, name: 'ब्लैंड बाक्स आगत'},
	],
	common: {
		app_name: "Blur",
		confirm: "पुष्टि करें",
		cancel: "रद्द करें",
		more: "अधिक",
		main: "मुख्य छवि",
		sub: "उपप्रोट",
		empty: "वर्तमान में कोई डाटा उपलब्ध नहीं है",
		success: "सफलता",
		copy_success: "कॉपी सफल！",
		copy_error: "नकल असफल！",
		passed: "पास",
		rejectrd: "अस्वीकारित",
		in_review: "पुनरावृत्ति",
		nodata: "वर्तमान में कोई सामग्री उपलब्ध नहीं है",
		delete: "मिटाएँ",
		add: "नया जोड़ें",
		buy: "खरीद करें",
		sell: "बेकाएँ",
		max: "अधिकतम",
		all: "पूर्ण",
		logout: "क्या आप निश्चित है कि आप लॉग आउट करना चाहते हैं？",
		walletLink: "कृपया अपने वालेट में इस कनेक्शन को खोलें！",
		connectWallet: "वालेट कनेक्ट करें",
	},
	home: {
		nav1: "साझेदार",
		nav2: "वालेट",
		nav3: "शीघ्र समाचार",
		nav4: "शिक्षण",
		nav5: "पसंदीदा सेवा",
		nav6: "मिनिंग",
		nav7: "कंपनी",
		quick_p1: "तुरंत कोइन पुनरार्ज करें",
		quick_p2: "TRC20- USDT, ERC20- USDT, इत्यादि समर्थन करता है",
		sort_tab1: "चार्ट बढ़ाएँ",
		sort_tab2: "24 घंटे व्यापारिक आवाज",
		sort_tit: "मार्केट",
		sort_price: "अन्तिम मूल्य",
		sort_rate: "24 घंटे बढ़ते हुए",
		sort_vol: "टार्नवर",

	},
	form: {
		place_username_email: "कृपया उपयोक्ता नाम/ईमेल भरें",
		place_password: "कृपया पासवर्ड भरें",
		place_confirm_password: "कृपया अपना पासवर्ड फिर भरें",
		place_email: "कृपया अपना इमेल पता भरें",
		send_email: "इमेल भेजा जा रहा है",
		place_email_captcha: "कृपया इमेल परीक्षण कोड भरें",
		place_username: "एक उपयोक्ता नाम भरें",
		place_yzm: "कृपया सत्यापन कोड भरें",
		place_invite: "कृपया आमन्त्रणा आईडी भरें",
		place_inner: "कृपया सामग्री भरें",
		place_select: "कृपया चुनें",
		label_oldpwd: "मौलिक लॉगइन पासवर्ड",
		place_oldpwd: "कृपया मौलिक लॉगइन पासवर्ड भरें",
		label_newpwd: "नया लॉगइन पासवर्ड",
		place_newpwd: "कृपया एक नया लॉगइन पासवर्ड भरें",
		label_crmpwd: "नया लॉगइन पासवर्ड पुष्टि करें",
		place_crmpwd: "कृपया नया लॉगइन पासवर्ड फिर भरें",
		place_country: "कृपया एक देश चुनें",
		place_name: "कृपया अपना नाम भरें",
		place_card_number: "कृपया अपना आईडी संख्या या पासपोर्ट संख्या भरें",
		place_card_main: "अपने आईडी कार्ड के आगे फोटो अपलोड करने के लिए क्लिक करें",
		label_currency: "मुद्रा",
		place_currency: "कृपया मुद्रा चुनें",
		label_account_number: "खाता संख्या",
		place_account_number: "कृपया अपना खाता संख्या भरें",
		message_buy_amount: "कृपया क्रिया मात्रा भरें",
		place_buy_tip: "कम से कम खरीद करें",
	},

	foot: {
		nav1: "घर पृष्ठ",
		nav2: "मार्केट",
		nav3: "ट्रांसेक्शन",
		nav4: "एसेट",
	},
	account: {
		login_title他: "स्वागत है",
		register_title: "रेजिस्टर में स्वागत है",
		contact: "ग्राहक सेवा संपर्क करने के लिए क्लिक करें",
		login: "लॉगइन",
		no_account: "अभी कोई खाता नहीं？",
		free_register: "मुक्त रिजिस्ट्रेशन",
		welcome_register: "रेजिस्टर में स्वागत है",
	},
	asset: {
		asset: "एसेट",
		total_asset: "कुल आसात",
		deposit: "फिर चार्ज करें",
		withdraw: "विडडवाल",
		available: "उपलब्ध",
		freeze: "जल्दीweather forecast",
		total: "कुल",
		wallet: "वालेट",
		select_link: "मुख्य संख्या चुनें",
		deposit_address: "पता फिर चार्ज करें",
		deposit_tip: "कृपया यूएसडीटी के सिवा किसी और मालिकों को इस पता में पुनरार्ज नहीं करें. इस पता में कोई अन्य मालिकों को पुनरार्ज नहीं कर सकता",
	},
	chart: {
		now: "वर्तमान",
		open_price: "मूल्य खोल रहा है",
		trans_amount: "ट्रांसेक्शन मात्रा",
		volumes: "टार्नवर",
		height: "उच्च",
		low: "न्यूनतम",
		buy: "खरीद करें",
		sell: "विक्रेत",
		buy_more: "अधिक खरीद करें",
		sell_short: "छोटा विक्रेत",
		buy_long: "लंबा जा रहा है",
		short_sell: "छोटा विक्रेत",
		spots: "स्पोट सामग्री",
		foreign_exchange: "विदेशी बदलें",
		market: "मार्केट",
		order_tab1: "स्थिति",
		order_tab2: "क्रम स्थापना",
		order_tab3: "बन्द स्थिति",
		surplus: "Balance",
		available_balance: "उपलब्ध",
		net_value: "नेट मूल्य",
		advance_charge: "आवृत्ति पैसा",
		unrealized_profits: "अज्ञात लाभ",
		open_price2: "मूल्य खोल रहा है",
		now_price: "मौजूदा मूल्य",
		lever_title: "स्तर समायोजन",
		lever: "लेवर",
		check_price: "मूल्य सीमा",
		market_price: "बाजार मूल्य",
		number: "मात्रा",
		total_amount: "कुल मात्रा",
		price: "मूल्य",
		now_entrust: "वर्तमान कमिशन",
		history_entrust: "इतिहासिक कमिशन",
		holding_positions: "होल्डिंग स्थिति",
		warehouse: "भण्डार द्वारा भण्डार",
		margin: "सीमा",
		from: "से",
		to: "में",
		pay: "पैसा",
		balance: "Balance",
		get: "प्राप्त करें",
		flash_exchange: "फ्लैश बदलें",
		hand_number: "हात गणना",
		registration_price: "हैंग यूनिट मूल्य",
		stop_profit: "उत्पन्न रोकें",
		stop_loss: "गुमान रोकें",
		need_margin: "डिपोस्ट आवश्यक",
		current_price: "मौजूदा मूल्य",
		direction: "दिशा",
		currency_trade: "मुद्रा ट्रांसेक्शन्स",
		delivery_time: "प्रदान समय",
		second: "सेकंड",
		buy_amount: "मात्रा खरीद करें",
		fee: "फीज हैंडलिंग",
		confirm_order: "क्रम पुष्टि करें",
		trade_history: "ट्रांसेक्शन इतिहास",
		turnover_number: "ट्रांसेक्शन मात्रा",
		turnovered: "पूरा ट्रांसेक्शन",
		opened: "खोलने के लिए",
		progress: "प्रगति में है",
		ended: "समाप्त",
		revoked: "पुनरावृत्ति",
		complete: "पूर्ण",
		time: "समय",
		operate: "प्रक्रिया",
		closing_position: "स्थिति बन्द कर रहा है",
		buy_price: "मूल्य खरीद करें",
	},
	market: {
		currency_trade: "मुद्रा ट्रांसेक्शन्स",
		spots: "स्पोट सामग्री",
		contract: "कंट्रांट",
		adjustment_accounts: "调खाता की समायोजन账",
	},
	table: {
		market: "मार्केट",
		rate: "24 घंटे बढ़ते हुए",
		open_price: "मूल्य खोल रहा है",
		last_price: "अन्तिम मूल्य",
	},
	my: {
		modify_login_pwd: "लॉगइन पासवर्ड बदलें",
		company: "कंपनी",
		help_center: "मदद केंद्र",
		history: "मुद्रा ट्रांसेक्शन इतिहास",
		buy: "खरीद करें",
		sell: "विक्रेत",
		buy_price: "मूल्य खरीद करें",
		type: "प्रकार",
		amount: "पैसा",
		final_price: "अंतिम मूल्य",
		profit_loss: "प्रोफाइट और हराम",
		state: "स्थिति",
		loss: "गुमान",
		profit: "लाभ",
		news: "शीघ्र समाचार",
		authentication: "सत्यापन",
		service: "पसंदीदा सेवा",
		asset: {
			amount_record: "मात्रा रेकॉर्ड",
			deposit_record: "रेकर्ड फिर चार्ज करें",
			withdraw_record: "वाटवाल रेकॉर्ड",
		},
		help: {
			p1: "तुम्हारे सेवा के साथ जहाँ, जहां भी",
			h1: "अनेक प्रश्न पूछे",
		},
		share: {
			share: "साझेदार",
			invite_link: "निमन्त्रणा लिंक",
			copy_link: "लिंक कॉपी करें",
		},
		wallet: {
			title: "वालेट पता",
			account_number: "खाता संख्या",
			add_address: "पता जोड़ें",

		}
	},
	finance: {
		finance_tit: "तालाबन्द मिनिंग",
		finance_label1: "अपेक्षित आज की उत्पन्न",
		finance_label2: "सम्पूर्ण आगत",
		finance_label3: "होस्ट आदेश",
		subscribe: "सदस्यता",
		day: "दिन",
		record: "रिकार्ड",
		deposit_amount: "पुनरार्ज मात्रा",
		received_amount: "प्राप्त मात्रा",
		exchange_rate: "बदला दर",
		ad_p1: "अन्तिम लाभ के लिए मिनिट",
		ad_p2: "प्लेटफ़ॉर्म के मिनिमिंग पूल में लाभ मिनने के लिए प्लेटफ़ॉर्म कंप्यूटरिंग मिनिमिंग मशीनों के लिए USDT को प्रविष्ट करने",
		join: "मैं सहभागी करना चाहता हूँ",
		ad_p3: "उत्पादन हाइलाइट",
		ad_p4: "एक उदाहरण दें",
		ad_tit1: "आवश्यकता के रूप में भण्डार और प्राप्त करें",
		ad_tit2: "दिनांक वितरण",
		ad_tit3: "सुरक्षा मापक",
		ad_tit4: "निरन्तर आगत",
		ad_tit5: "उसी दिन में प्राथमिकता प्रारंभ होता है",
		ad_des1: "विभाजित पैसा आउट साइकल",
		ad_des2: "मौजूदा प्राथमिकता",
		ad_des3: "100% सुरक्षित",
		ad_des4: "छुट्टी आयात",
		ad_des5: "सफलतापूर्वक डोपोसिट के बाद",
		ad_sub_tit1: "गणना",
		ad_sub_tit2: "कंट्रांट के विरोध के लिए शिक्षा",
		ad_sub_p1: "सदस्यों ने प्लेटफ़ॉर्म पर 10000U की स्थिति को ताला लगाया और 5 दिन के साइकल के साथ धन प्रबंधन उत्पादन को चुना और 0.3% से 0.4% से दिन के आउटपुट के",
		ad_sub_p2: "न्यूनतम: 10000U x 0. 3%=30 U",
		ad_sub_p3: "अधिकतम: 10000U x 0.4%=40 U",
		ad_sub_p4: "5 दिनों में, आप 150U से 200U में परिणाम प्राप्त कर सकते हैं, जो दिन वितरित होते हैं और कोई समय में पहुँच सकते हैं. प्राधानिक में ताला मियाद होने के बाद, यह स्",
		ad_sub_p5: "यदि आप अप्रत्याशित प्राधानिक को स्थानान्तरण करना चाहते हैं, तो एक शिक्षा होगा, जो डिफ़ॉल्ट स्थान के रूप में गणना किया जाता है * बा",
		ad_sub_p6: "उदाहरण के लिए, यदि तालाबन्द के लिए डिफ़ॉल्ट सेटेंशन अनुपात 0.5%, और बाकी 3 दिन के लिए समाप्त होगी और तालाबन्द स्थितियों की संख्या 1000 है, डिफ़ॉल्ट सेटिंग 0.5% * 3 * 1000=15U है, और",
	},

	city: {
		albania: "阿尔巴尼亚",
		algeria: "阿尔及利亚",
		argentina: "阿根廷",
		armenia: "亚美尼亚",
		australia: "澳大利亚",
		pakistan: "巴基斯坦",
		austria: "奥地利",
		bahrain: "巴林",
		belgium: "比利时",
		bosnia_and_Herzegovina: "波黑",
		brazil: "巴西",
		brunei: "文莱",
		bulgaria: "保加利亚",
		cambodia: "柬埔寨",
		canada: "加拿大",
		cameroon: "喀麦隆",
		chile: "智利",
		colombia: "哥伦比亚",
		costa_Rica: "哥斯达黎加",
		croatia: "克罗地亚",
		cyprus: "塞浦路斯",
		czech_Republic: "捷克",
		denmark: "丹麦",
		dominican_Republic: "多米尼亚共和国",
		egypt: "埃及",
		estonia: "爱沙尼亚",
		ethiopia: "埃塞俄比亚",
		finland: "芬兰",
		france: "法国",
		georgia: "格鲁吉亚",
		germany: "德国",
		ghana: "加纳",
		greece: "希腊",
		guyana: "圭亚那",
		honduras: "洪都拉斯",
		hong_Kong_China: "中国香港",
		hungary: "匈牙利",
		iceland: "冰岛",
		ireland: "爱尔兰",
		italy: "意大利",
		india: "印度",
		indonesia: "印度尼西亚",
		israel: "以色列",
		iran: "伊朗",
		iraq: "伊拉克",
		japan: "日本",
		kazakstan: "哈萨克斯坦",
		kenya: "肯尼亚",
		korea: "韩国",
		kuwait: "科威特",
		kyrgyzstan: "吉尔吉斯斯坦",
		laos: "老挝",
		latvia: "拉脱维亚",
		lithuania: "立陶宛",
		luxembourg: "卢森堡",
		macao_China: "中国澳门",
		macedonia: "马其顿",
		malaysia: "马来西亚",
		malta: "马耳他",
		mexico: "墨西哥",
		moldova: "摩尔多瓦",
		monaco: "摩纳哥",
		mongolia: "蒙古",
		montenegro: "黑山",
		morocco: "摩洛哥",
		myanmar: "缅甸",
		netherlands: "荷兰",
		new_Zealand: "新西兰",
		nepal: "尼泊尔",
		nigeria: "尼日利亚",
		norway: "挪威",
		oman: "阿曼",
		palestine: "巴勒斯坦",
		panama: "巴拿马",
		paraguay: "巴拉圭",
		peru: "秘鲁",
		philippines: "菲律宾",
		poland: "波兰",
		portugal: "葡萄牙",
		puerto_Rico: "波多黎各",
		qatar: "卡塔尔",
		romania: "罗马尼亚",
		russia: "俄罗斯",
		republic_of_Trinidad_and_Tobago: "特立尼达和多巴哥",
		rwanda: "卢旺达",
		saudi_Arabia: "沙特阿拉伯",
		serbia: "塞尔维亚",
		singapore: "新加坡",
		slovakia: "斯洛伐克",
		slovenia: "斯洛文尼亚",
		south_Africa: "南非",
		spain: "西班牙",
		sri_Lanka: "斯里兰卡",
		sweden: "瑞典",
		switzerland: "瑞士",
		taiwan_China: "中国台湾",
		tajikistan: "塔吉克斯坦",
		tanzania: "坦桑尼亚",
		thailand: "泰国",
		turkey: "土耳其",
		turkmenistan: "土库曼斯坦",
		ukraine: "乌克兰",
		united_Arab_Emirates: "阿联酋",
		united_Kingdom: "英国",
		united_States: "美国",
		uzbekistan: "乌兹别克斯坦",
		venezuela: "委内瑞拉",
		vietnam: "越南",
		afghanistan: "阿富汗",
		angola: "安哥拉",
		azerbaijan: "阿塞拜疆",
		bangladesh: "孟加拉国",
		belarus: "白俄罗斯",
		belize: "伯利兹",
		benin: "贝宁",
		bhutan: "不丹",
		bolivia: "玻利维亚",
		botswana: "博茨瓦纳",
		british_Virgin_Islands: "英属维京群岛",
		burkina_Faso: "布基纳法索",
		burundi: "布隆迪",
		cape_Verde: "佛得角",
		cayman_Islands: "开曼群岛",
		central_African_Republic: "中非共和国",
		chad: "乍得",
		comoros: "科摩罗",
		the_Republic_of_the_Congo: "刚果（布）",
		democratic_Republic_of_the_Congo: "刚果（金）",
		djibouti: "吉布提",
		ecuador: "厄瓜多尔",
		el_Salvador: "萨尔瓦多",
		equatorial_Guinea: "赤道几内亚",
		eritrea: "厄立特里亚",
		fiji: "斐济",
		gabon: "加蓬",
		gambia: "冈比亚",
		greenland: "格陵兰",
		guatemala: "危地马拉",
		guinea: "几内亚",
		haiti: "海地",
		isle_of_Man: "马恩岛",
		cote_d_Ivoire: "科特迪瓦",
		jamaica: "牙买加",
		jordan: "约旦",
		lebanon: "黎巴嫩",
		lesotho: "莱索托",
		liberia: "利比里亚",
		libya: "利比亚",
		madagascar: "马达加斯加",
		malawi: "马拉维",
		maldives: "马尔代夫",
		mali: "马里",
		mauritania: "毛里塔尼亚",
		mauritius: "毛里求斯",
		mozambique: "莫桑比克",
		namibia: "纳米比亚",
		nicaragua: "尼加拉瓜",
		republic_of_Niger: "尼日尔",
		north_Korea: "朝鲜",
		reunion: "留尼汪",
		san_Marino: "圣马力诺",
		senegal: "塞内加尔",
		sierra_Leone: "塞拉利昂",
		somalia: "索马里",
		sudan: "苏丹",
		suriname: "苏里南",
		eswatini: "斯威士兰",
		syria: "叙利亚",
		togo: "多哥",
		tonga: "汤加",
		tunisia: "突尼斯",
		united_States_Virgin_Islands: "美属维尔京群岛",
		uganda: "乌干达",
		uruguay: "乌拉圭",
		vatican: "梵蒂冈",
		yemen: "也门",
		yugoslavia: "南斯拉夫",
		zambia: "赞比亚",
		zimbabwe: "津巴布韦",
		china: "中国",
	}
};
